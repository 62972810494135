<template>
    <div class="c-detail">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="服务明细详情" fixed placeholder safe-area-inset-top />
        <div class="s-title">
            <div class="left">机构名</div>
            <div class="right">签章次数</div>
        </div>
        <div class="content">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                >
                <div class="s-items" v-for='(item,index) in list' :key='index'>
                    <!-- <div class="left"><img src="../../assets/signature.png" alt=""><div class="name">{{item.org_name}}</div></div> -->
                    <div class="left"><div class="num">{{index+1}}.</div><div class="name">{{item.org_name}}</div></div>
                    <div class="right">{{item.total}}</div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import { button, Toast ,NavBar , Tab, Tabs ,Field ,List} from 'vant';
export default {
    name:"detail",
    data(){
        return{
            org_code:this.$route.query.org_code,
            list:[],
            loading: false,
            finished: false,
            current:0
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        getService(){
            this.current ++
            // Toast.loading({
            //     duration: 0,
            //     message: '加载中...',
            //     forbidClick: true,
            // });
            this.$post(`/finance/api/cfcaFee/sealDetail`,{appid: "28f10c6186cc11ebb35f00163e089d08",param:{orgCode:this.org_code,page:this.current,limit:20}}).then(res => {
                if (res.code === '200') {
                    if(this.list.length == 0){
                        this.list = res.data.records
                    }else{
                        this.list = [...this.list,...res.data.records]
                    }

                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (res.data.records.length < 20) {
                        this.finished = true;
                    }
                    // this.list = res.data.records
                    // Toast.clear();
                }
            })
        },
        onLoad() {
            setTimeout(() => {
                this.getService(this.status)
            }, 1000);
        },
    },
    mounted() {
        // this.getService()
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        [List.name]: List,
    }
}
</script>

<style lang="scss" scoped>
   
</style>