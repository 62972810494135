<template>
    <div class="c-detail">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="服务明细详情" fixed placeholder safe-area-inset-top />
        <div class="s-title">
            <div class="left">机构名</div>
            <div class="right">案件数</div>
        </div>
        <div class="content">
            <div class="s-items" v-for='(item,index) in list' :key='index' @click='link(item.deptCode)'>
                <div class="left"><img src="../../assets/mediate.png" alt=""><div class="name">{{item.deptName}}</div></div>
                <div class="right">{{item.count}} <span class="symbol">&#155</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import { button, Toast ,NavBar , Tab, Tabs ,Field } from 'vant';
import ShowTotal from '@/components/ShowTotal'
export default {
    name:"detail",
    data(){
        return{
           deptCode:this.$route.query.deptCode,
           list:[]
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        link(deptCode){
            this.$router.push({path:'/common/batch',query:{deptCode:deptCode,feeType:"30060005"}})
        },
        getService(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get(`/finance/api/fee/statisticCaseNumber`,{feeType:"30060005"}).then(res => {
                if (res.code === '200') {
                    this.list = res.data
                    this.list.forEach(item => {
                        if(item.deptCode == this.deptCode){
                            this.list = item.children
                        }
                    })
                    console.info(this.list)
                    Toast.clear();
                }
            })
        },
    },
    mounted() {
        this.getService()
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        ShowTotal
    }
}
</script>

<style lang="scss" scoped>
    
</style>